import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './Hero.scss';

const Hero = ({ title, subTitle, children, backgrounds }) => {
  const [background, setBackground] = useState();

  useEffect(() => {
    if (backgrounds && backgrounds.length > 0) {
      const backgroundIndex = Math.floor(Math.random() * backgrounds.length);
      setBackground(backgrounds[backgroundIndex]);
    }
  }, [backgrounds]);

  const heroStyles = {};

  if (background) {
    heroStyles.backgroundImage = `url("${background}")`;
  }

  return (
    <section
      className={classNames('hero is-fullheight is-primary', {
        'has-background-image': !!background,
      })}
      style={heroStyles}
    >
      {background && <div className="hero-overlay" />}
      <div className="hero-body">
        <div className="container has-text-centered ">
          {title && <h1 className="title is-1 has-text-white">[{title}]</h1>}
          {subTitle && (
            <h2 className="subtitle is-3 has-text-white">{subTitle}</h2>
          )}
          <p></p>
          {children}
        </div>
      </div>
    </section>
  );
};

export default Hero;
