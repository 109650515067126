import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import PricesTable from '../components/PricesTable';
import ContactForm from '../components/ContactForm';
import AnchorOffset from '../components/AnchorOffset';

export default function Home({ data }) {
  const { allPricesJson: prices, backgrounds } = data;
  const backgroundsImages = backgrounds.edges.map(
    item => item.node.childImageSharp.fluid.srcWebp
  );

  return (
    <Layout>
      <AnchorOffset id="home" />
      <Hero
        title="Rijschool Damme"
        subTitle=""
        backgrounds={backgroundsImages}
      />
      <div className="container px-3 py-6">
        <div className="columns is-multiline">
          <div className="column is-full-tablet is-half-desktop">
            <AnchorOffset id="prices" />
            <h1 className="title">Prijzen</h1>
            <PricesTable items={prices.edges} />
          </div>
          <div className="column">
            <AnchorOffset id="contact" />
            <h1 className="title">Contact</h1>
            <address>
              <strong>Rijschool Damme</strong>
              <br />
              Chico mendesring 530
              <br />
              3315 WL Dordrecht
              <br />
              <strong>Tel:</strong> <a href="tel:+31650670067">06-50670067</a>
              <br />
              <strong>Kvk:</strong> 64261247
              <br />
              <strong>E-mail:</strong>{' '}
              <a href="mailto:m.e.damme.1990@gmail.com">
                m.e.damme.1990@gmail.com
              </a>
              <br />
              <strong>Rijschoolnummer:</strong> 2413G8
              <br />
            </address>
          </div>
          {/* <div className="column">
            <ContactForm />
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    backgrounds: allFile(filter: { relativeDirectory: { eq: "home" } }) {
      edges {
        node {
          id
          name
          publicURL
          relativeDirectory
          relativePath
          childImageSharp {
            fluid(maxWidth: 1920) {
              srcWebp
            }
          }
        }
      }
    }
    allPricesJson {
      edges {
        node {
          id
          name
          price
        }
      }
    }
  }
`;
