import React from 'react';
import './PricesTable.scss';

const PricesTable = ({ items }) => (
  <table className="c-prices-table table is-striped is-fullwidth">
    <tbody>
      {items &&
        items.map(({ node: item }) => (
          <tr key={item.id}>
            <th>{item.name}</th>
            <td className="c-prices-table__price">{item.price}</td>
          </tr>
        ))}
    </tbody>
  </table>
);

export default PricesTable;
