import React from 'react';
import './AnchorOffset.scss';

const AnchorOffset = ({ id }) => (
  <div className="c-anchor-offset">
    <div id={id} />
  </div>
);

export default AnchorOffset;
